import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"

import React from "react"
import { getCookie } from "./cookie"
import i18next from "../../i18n/config"
import { shared } from "../../config/shared"
import { useLocalesState } from "../context/locales"

const buildPath = (language, region, path) => {
    const splittedPath = path.split("?")
    if (process.env.PATH_PREFIX) return path
    if (["/imprint", "/privacy", "/terms", "/revocation-policy"].some(a => splittedPath[0].includes(a))) return path
    let uri = splittedPath[0].split("/")
    const search = splittedPath[1]
    if (process.env.IGNORE_REGION_IN_PATH ? /^\w{2}$/.test(uri[1]) : /^\w{2}-\w{2}$/.test(uri[1])) {
        uri.splice(
            1,
            1,
            language === process.env.DEFAULT_LANGUAGE
                ? ""
                : language + (!process.env.IGNORE_REGION_IN_PATH ? `-${region.toLowerCase()}` : "")
        )
    } else {
        uri.splice(
            1,
            0,
            language === process.env.DEFAULT_LANGUAGE
                ? ""
                : language + (!process.env.IGNORE_REGION_IN_PATH ? `-${region.toLowerCase()}` : "")
        )
    }
    if (uri[1] === "") {
        uri.splice(1, 1)
    }
    if (search) uri.push(`?${search}`)
    return uri.join("/") || "/"
}

const navigate = (path, options) => {
    const language = i18next.language
    const region = getCookie("__region__")
    return gatsbyNavigate(buildPath(language.split(/-|_/)[0], region, path), options)
}

const Link = props => {
    const { language, region } = useLocalesState()
    if (!props.to) return null

    const clone = { ...props }

    delete clone.children
    delete clone.to

    return (
        <GatsbyLink {...clone} to={buildPath(language.split(/-|_/)[0], region, props.to)}>
            {props.children}
        </GatsbyLink>
    )
}

const A = props => {
    if (!props.href) return null

    const clone = { ...props }

    try {
        const url = new URL(props.href)
        const path = url.pathname
        const origin = url.origin

        delete clone.href
        delete clone.children

        if (origin === shared.router.url || origin === shared.router.menuUrl) {
            return (
                <Link {...clone} to={path + url.search}>
                    {props.children}
                </Link>
            )
        } else if (
            !props.noURLReplace &&
            (origin === process.env.REPLACE_MENU_PART_SHOP ||
                origin === process.env.REPLACE_MENU_PART_WEB ||
                origin === process.env.REPLACE_MENU_PART_ACCOUNT)
        ) {
            return (
                <a
                    {...clone}
                    href={props.href
                        .replace(process.env.REPLACE_MENU_PART_WEB, process.env.WEB_URL)
                        .replace(process.env.REPLACE_MENU_PART_SHOP, process.env.SHOP_URL)
                        .replace(process.env.REPLACE_MENU_PART_ACCOUNT, process.env.ACCOUNT_URL)}
                >
                    {props.children}
                </a>
            )
        } else {
            return (
                <a {...clone} href={props.href}>
                    {props.children}
                </a>
            )
        }
    } catch {
        return null
    }
}

export { navigate, Link, A, buildPath }
