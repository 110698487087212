import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ToastContainer, cssTransition } from "react-toastify"

import { ClientProvider } from "../shareact/context/client"
import React from "react"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import client from "../shareact/utils/client"

const WrapRoot = ({ element }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
                enabled: typeof window !== "undefined",
            },
        },
    })

    const FadeFromRightOrTop = cssTransition({
        enter: "slideIn",
        exit: "slideOut",
    })

    return (
        <ClientProvider client={client}>
            <QueryClientProvider client={queryClient}>
                <ToastContainer autoClose={6000} transition={FadeFromRightOrTop} />
                {element}
                {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        </ClientProvider>
    )
}

export default WrapRoot
